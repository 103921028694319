@import "@sass/layout-variables";
@import "@sass/mixins/layout-mixins";

.start-page {
  @include page;

  #js-search-app {
    width: 100%;
  }

  &__content-container {
    @include block;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2.125rem;

    @media (min-width: $lg) {
      flex-direction: row;
      gap: 2rem;
      padding-bottom: 0;
    }
  }
}